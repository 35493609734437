$('.btn-menu').click(function(e){
	var target = $(this).attr('data-target');
	$(target).toggleClass('active');
	$(this).toggleClass('active');
	$('.bg-menu').fadeIn(400);
});

$('.bg-menu').click(function(){
	$('.main-navigation').removeClass('active');
	$('.btn-menu').removeClass('active');
	$(this).fadeOut(400);
});